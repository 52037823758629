<template>
  <main>
    <div class="status_wrapper statuss">
      <Form
        id="form"
        :form_id="status_form_id"
        :uid="uid"
        @on_error="viewError"
         :hideError="hiderror"
        :submission_id="submission_id"
        :server="server"
        v-on:results="getResult"
      />
    </div>
  </main>
</template>
<script>
var consts = require("../config");
import axios from "axios";
export default {
  name: "Status",
  data: function() {
    return {
      questionnaire: true,
      hiderror: true,
      submission_id: this.$route.params._id,
      status_form_id: "630cbde22aeb870016d7a45e",
      server: "https://api.lucien.ai",
      uid: this.$route.params._secu,
    };
  },
  async mounted() {
    let { data } = await axios.get(
      "https://api.lucien.ai/api/v2/submissions/" +
        this.submission_id +
        "?uid=" +
        this.uid
    );
    if (data.question_1) {
      this.questionnaire = false;
    } else {
      this.questionnaire = true;
    }
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          allowOutsideClick: false,
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    getResult: function(result) {
      if (result._id) {
        this.$swal({
          type: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          html:
            "<b>Votre statut a bien été mis à jour. Vous serez informé de son traitement par email.</b>",
        }).then((result) => {
          window.location.href = "/status/"+this.$route.params._id+"/"+this.uid;
        });
      } else {
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          text: "Une erreur est survenue",
        });
      }
    },
    viewError(err) {
      console.log(err);
      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        title: "Désolé, une erreur est survenue.",
        text: err,
      }).then(function() {
        window.location.href = "/";
      });
    },
  },
};
</script>